$primary-color: #7CE7F2;
$main-black-color: #000;
$base-color-font: #fff;
$base-gray-color: #1C1C1C;
$light-gray-color: #8F8F8F;
$main-gray-color: #dddddd;
$base-read: #EA4335;
$base-blue: #3A5999;

$main-bg: #08101a;
$header-bg: #081019;

// border
$collapse-border: #1E252E;

// table
$table-striped-bg: #1E252E;

// card
$card-img-bg: #1E252E;

// actor page
$actor-header-bg: #741c3b;

// border
$border-white: white;

// button 
$btn-color: #202020;
$btn-bg: $primary-color;
$btn-border-gray: $light-gray-color;
$btn-bg-loade-more: #4B5057;

// form
$form-control-bg: #1E252E;
$form-control-color: $light-gray-color;

// search link
$search-link-color: $light-gray-color;

// modal 
$modal-wrap-bg: rgba(0, 0, 0, .5);
$modal-container-bg: #081019;

// footer
$footer-bg: #1E252E;
$footer-color-font: #919EAB;
$footer-copyright-border-color: #343B43;

// dropdown
$dropdown-bg: #1E252E;

// divider
$divider-bg: #595959;

// scroll
$scroll-box-color: #12131E;

// eposodes
$eposodes-color: #919EAB;