/** Generated by FG **/
@font-face {
  font-family: 'IBMPlexSansArabic';
  src: url('~@/assets/fonts/IBMPlexSansArabic-Regular.eot');
  src: local('☺'),
    url('~@/assets/fonts/IBMPlexSansArabic-Regular.woff') format('woff'),
    url('~@/assets/fonts/IBMPlexSansArabic-Regular.ttf') format('truetype'),
    url('~@/assets/fonts/IBMPlexSansArabic-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: 'IBMPlexSansArabic';
  src: url('~@/assets/fonts/IBMPlexSansArabic-Medium.eot');
  src: local('☺'),
    url('~@/assets/fonts/IBMPlexSansArabic-Medium.woff') format('woff'),
    url('~@/assets/fonts/IBMPlexSansArabic-Medium.ttf') format('truetype'),
    url('~@/assets/fonts/IBMPlexSansArabic-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: 'IBMPlexSansArabic';
  src: url('~@/assets/fonts/IBMPlexSansArabic-SemiBold.eot');
  src: local('☺'),
    url('~@/assets/fonts/IBMPlexSansArabic-SemiBold.woff') format('woff'),
    url('~@/assets/fonts/IBMPlexSansArabic-SemiBold.ttf') format('truetype'),
    url('~@/assets/fonts/IBMPlexSansArabic-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: 'IBMPlexSansArabic';
  src: url('~@/assets/fonts/IBMPlexSansArabic-Bold.eot');
  src: local('☺'),
    url('~@/assets/fonts/IBMPlexSansArabic-Bold.woff') format('woff'),
    url('~@/assets/fonts/IBMPlexSansArabic-Bold.ttf') format('truetype'),
    url('~@/assets/fonts/IBMPlexSansArabic-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:
    url('~@/assets/fonts/icomoon.ttf?hdalhw') format('truetype'),
    url('~@/assets/fonts/icomoon.woff?hdalhw') format('woff'),
    url('~@/assets/fonts/icomoon.svg?hdalhw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// @font-face {
//   font-family: 'SF-PRO-Display';
//   src: url('~@/assets/fonts/SFProDisplay-Regular.eot');
//   src: local('☺'),
//     url('~@/assets/fonts/SFProDisplay-Regular.woff') format('woff'),
//     url('~@/assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
//   // url('../../../assets/fonts/Poppins-Regular.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "SF-PRO-Display";
//   src: url('~@/assets/fonts/SFProDisplay-Regular.woff2'),
//     url('~@/assets/fonts/SFProDisplay-Regular.woff'),
//     url('~@/assets/fonts/SFProDisplay-Regular.ttf'),
//     url('~@/assets/fonts/SFProDisplay-Regular.eot');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "SF-PRO-Display";
//   src: url('~@/assets/fonts/SFProDisplay-Semibold.woff2'),
//     url('~@/assets/fonts/SFProDisplay-Semibold.woff'),
//     url('~@/assets/fonts/SFProDisplay-Semibold.ttf'),
//     url('~@/assets/fonts/SFProDisplay-Semibold.eot');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "SF-PRO-Display";
//   src: url('~@/assets/fonts/SFProDisplay-Bold.woff2'),
//     url('~@/assets/fonts/SFProDisplay-Bold.woff'),
//     url('~@/assets/fonts/SFProDisplay-Bold.ttf'),
//     url('~@/assets/fonts/SFProDisplay-Bold.eot');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

/** Poppins-Regular **/
// @font-face {
//   font-family: 'Poppins-Regular';
//   src: url('../../../assets/fonts/Poppins-Regular.eot');
//   src: local('☺'),
//     url('../../../assets/fonts/Poppins-Regular.woff') format('woff'),
//     url('../../../assets/fonts/Poppins-Regular.ttf') format('truetype'),
//     url('../../../assets/fonts/Poppins-Regular.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }