
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.navbar {

	&__mobile-bottom {
		background-color: #000;
		bottom: 0;
		height: rem(70);
		width: 100%;
		position: fixed;
		z-index: 9;

		display: flex;
		align-items: center;
		justify-content: space-around;

	}

	&-mobile__item-link {
		color: $light-gray-color !important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		a {
			color: $light-gray-color !important;
			// padding: rem(8);
		}

		span {
			font-size: rem(10);
			line-height: math.div(15, 10);
		}
	}
}
