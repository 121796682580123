
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.profiles {
  &__items {
    margin-bottom: rem(32);
    display: flex;
    align-items: center;
    column-gap: rem(20);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  &__img {
    border-radius: 50%;
    background-color: $light-gray-color;
    height: rem(140);
    width: rem(140);
    margin-bottom: rem(10);
  }

  &__name {
    color: $base-color-font;
    font-size: rem(18);
    font-weight: 600;
    line-height: math.div(21, 18);
  }

  &__link {
    text-align: center;
  }
}
