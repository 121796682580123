
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.btn {
  background-color: $btn-bg;
  border-radius: rem(5);
  border: rem(2) solid transparent;
  color: $btn-color;
  font-size: rem(16);
  font-weight: 600;
  line-height: math.div(20, 16);
  padding: rem(9) rem(30);
  min-width: rem(125);
  height: rem(44);
  width: auto;
  transition: all 200ms ease-out;

  &:hover {
    background-color: darken($color: $btn-bg, $amount: 10%);
  }

  &[disabled] {
    pointer-events: none;
  }

  &__link {
    background-color: transparent;
    color: $primary-color;
    height: auto;
    font-weight: 400;
    font-size: rem(16);
    line-height: math.div(20, 16);
    text-decoration: underline;
    padding: 0;

    &:hover {
      background-color: transparent;
      text-decoration: none;
    }
  }

  &__inline {
    background-color: transparent;
    color: $base-color-font;

    &:hover {
      background-color: transparent;
      color: darken($color: $base-color-font, $amount: 10%);
    }
  }

  &__border {
    background-color: transparent;
    border-color: $btn-bg;
    color: $btn-bg;
    height: rem(44);

    &:hover {
      background-color: $btn-bg;
      border-color: $btn-bg;
      color: $btn-color;
    }

    &[disabled] {
      border-color: $light-gray-color;
      color: $light-gray-color;
    }
  }

  &__close {
    height: rem(30);
    width: rem(30);
    min-width: auto;
    padding: 0;

    @extend %flex__content--center;

    @extend .btn__inline;

  }

  &__icon {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), #7CE7F2;
    color: $base-color-font;
    font-size: rem(20);
    padding: rem(5);
    min-width: auto;
    width: rem(48);


    &.active {
      background-color: $primary-color;
      color: $base-gray-color;
    }
  }

  &__google {
    background-color: $base-read;
    color: $base-color-font;
    width: 100%;

    &:hover {
      background-color: $base-read;
      opacity: .8;
    }
  }

  &__facebook {
    background-color: $base-blue;
    color: $base-color-font;
    width: 100%;

    &:hover {
      background-color: $base-blue;
      opacity: .8;
    }
  }

  &__xs {
    @include media-breakpoint-down(xs) {
      font-size: rem(14);
      min-width: 90%;
      padding: rem(9);
    }
  }

  &.disable {}
}
