
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.footer {
  background-color: $footer-bg;
  padding-bottom: rem(16);

  @include media-breakpoint-down(xs) {
    display: none;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    column-gap: rem(100);
    justify-content: space-between;

    padding-top: rem(40);
    padding-bottom: rem(40);

    @include media-breakpoint-down(lg) {
      column-gap: rem(30);
    }

    @include media-breakpoint-down(sm) {
      align-items: center;
      flex-direction: column;
      row-gap: rem(30);
    }
  }

  &__col {

    // max-width: rem(195);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      max-width: rem(225);
      display: flex;
      flex: 5 1 0%;
    }
  }

  &__brand {
    margin-bottom: rem(20);

    @include media-breakpoint-down(xs) {
      display: none;
    }

    &-img {
      width: rem(190);
    }
  }

  &__desc {
    color: $footer-color-font;
    font-size: rem(14);
    font-weight: 400;
    line-height: 150%;
    max-width: rem(206);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-nav-link {
    display: flex;
    flex-direction: column;
    row-gap: rem(30);

    @include media-breakpoint-down(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: rem(30);
      row-gap: rem(20);
      width: 100%;
    }

    &__item {
      a {
        color: $footer-color-font;
        font-size: rem(16);
        font-weight: 500;
        line-height: math.div(24, 16);

        &:hover {
          text-decoration: underline;
          opacity: .8;
        }

        @include media-breakpoint-down(sm) {
          font-size: rem(16);
          line-height: math.div(24, 16);
        }
      }
    }
  }

  &-social {
    &__items {
      display: flex;
      flex-direction: column;
      row-gap: rem(30);

      @include media-breakpoint-down(sm) {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: rem(30);
      }
    }

    &__title {
      font-size: rem(16);
      font-weight: 500;
      line-height: math.div(24, 16);

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      column-gap: rem(15);

      img {
        height: rem(24);
        width: rem(24);

        @include media-breakpoint-down(sm) {
          height: rem(32);
          width: rem(32);
        }
      }

      span {
        color: $primary-color;
        font-size: rem(16);
        font-weight: 500;
        line-height: math.div(24, 16);

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}


.copyright {
  border-top: rem(1) solid $footer-copyright-border-color;
  padding: rem(16) 0;

  &__text {

    font-weight: 500;
    font-size: rem(14);
    line-height: math.div(16, 14);
    color: $footer-color-font;
  }
}
