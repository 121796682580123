
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.header {
  background-color: $header-bg;
  width: 100%;
  height: 226px;
  top: -138px;
  position: sticky;
  z-index: 9;

  @include media-breakpoint-down(xs) {
    background-color: #000;
    height: 181px;
  }

  &__brand {

    @include media-breakpoint-down(xs) {
      margin: 0;
    }

    &-img {
      max-width: rem(190);
    }

    &-img--mobile {
      max-height: rem(30);
    }
  }

  &__navbar {
    height: 88px;
    left: 0;
    right: 0;
    transform: translateY(0);
    transition: transform 1s ease 0s;
    z-index: 100;
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    @include media-breakpoint-down(xs) {
      height: 45px;
    }
  }
}

.ad-content {

  // .ad-content__container

  &__container {
    min-height: 138px;
    clip-path: inset(0);
  }

  // .ad-content__section

  &__section {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 138px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.navbar {
  height: rem(96);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(xs) {
    height: rem(44);
    justify-content: center;
  }

  &__links {
    display: flex;
    align-items: center;
    column-gap: rem(32);
  }

  &__link-menu {
    font-weight: 600;
    line-height: math.div(24, 16);

    &:hover {
      color: $primary-color;
      text-decoration: underline;

    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__group {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      justify-content: center;
      width: 100%;
    }

    .button__group {
      margin-right: rem(30);

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }
    }

    .user__group {
      display: flex;
      align-items: center;
      column-gap: rem(20);
    }
  }

  &__mobile-bottom {
    background-color: #000;
    bottom: 0;
    height: rem(70);
    width: 100%;
    position: fixed;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: space-around;

  }

  &-mobile__item-link {
    color: $light-gray-color !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      color: $light-gray-color !important;
      // padding: rem(8);
    }

    span {
      font-size: rem(10);
      line-height: math.div(15, 10);
    }
  }

  .button__group {
    button {
      font-size: rem(16);
      line-height: math.div(20, 16);
    }
  }
}

.sign-in-link {
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;
}

.modal-watchlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;


  p {
    margin-bottom: rem(15);
  }

  .circle-icon {
    border: rem(2) solid $primary-color;
    border-radius: 50%;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(35);
    height: rem(70);
    width: rem(70);
    margin-bottom: rem(30);
  }

  .sign-in-link {
    color: $primary-color;
    cursor: pointer;
    text-decoration: underline;
  }
}

.btn__border {
  width: 100%;
}

.auth-sign__button {
  width: 100%;
}
