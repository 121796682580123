.card {
	display: flex;
	flex-direction: column;
	row-gap: rem(20);
	max-width: rem(264);
	width: 100%;
	position: relative;

	@include media-breakpoint-down(xs) {
		max-width: rem(100);
	}

	&:hover {
		.card__img-hover {
			visibility: visible;
			opacity: 1;
		}
	}

	// .card__link
	&__link {

		.card__img-no {
			cursor: pointer;

			&:hover {
				color: $primary-color;
			}
		}
	}

	// .card__img-block

	&__img-block {
		background-color: $card-img-bg;
		border-radius: rem(10);
		height: rem(406);

		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(xs) {
			height: rem(165);
		}

		img {
			@extend %img-view;
		}
	}

	// .card__img-no

	&__img-no {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: rem(70);
		color: $light-gray-color;
		height: 100%;
	}

	// .card__img-hover

	&__img-hover {
		border-radius: rem(16) rem(16) 0 0;
		top: 0;
		width: 340px;
		height: 480px;
		left: -35px;
		position: absolute;
		z-index: 3;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		transition: all 400ms ease-in-out;

		img {
			@extend %img-view;
		}
	}

	// .card__nav

	&__nav {
		row-gap: 20px;
		@extend %img-nav;
	}

	// .card__title

	&__title {
		color: $base-color-font;
		font-weight: 700;
		font-size: rem(28);
		line-height: math.div(30, 28);

		@include media-breakpoint-down(xs) {
			font-size: rem(16);
			width: rem(107);
			height: rem(34);
			padding: 0 rem(10);
			overflow: hidden;
		}
	}
}