
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.card-form {

  // .card-form__item-btn

  &__item-btn {
    margin-bottom: rem(32);
  }

  // .card-form__item-or

  &__item-or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(32);
    column-gap: rem(5);
  }
}

.or-style {

  // .or-style__left

  &__left,
  &__right {
    background-color: $light-gray-color;
    height: rem(1);
    width: 100%;
  }

  // .or-style__center

  &__center {
    color: $light-gray-color;
    font-weight: 500;
    font-size: rem(14);
    line-height: math.div(16, 14);
  }

  // .or-style__right

  &__right {}
}
