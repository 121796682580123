//Подключение шрифта
@mixin font($font_name, $file_name, $weight, $style) {
  @font-face {
    font-family: $font_name;
    font-display: swap;
    src: url('../fonts/#{$file_name}.woff2') format('woff2'),
    url('../fonts/#{$file_name}.woff') format('woff');
    font-weight: #{$weight};
    font-style: #{$style};
  }
}

//Percent
@function percent($px, $from) {
  $result: math.div($px, $from) * 100%;
  @return $result;
}

//REM
@function rem($px) {
  $result: math.div($px, 16)+rem;
  @return $result;
}

//EM
@function em($px, $current: 16) {
  $result: math.div($px, $current)+em;
  @return $result;
}

//Адаптивное свойство

// Mixins
@mixin media-breakpoint-up($size) {
  @if $size ==xs {
    @media (max-width: 575px) {
      @content;
    }
  }

  @else if $size ==sm {
    @media (min-width: 576px) {
      @content;
    }
  }

  @else if $size ==md {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $size ==lg {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $size ==xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin media-breakpoint-down($size) {
  @if $size ==xs {
    @media (max-width: 575.98px) {
      @content;
    }
  }

  @else if $size ==sm {
    @media (max-width: 767.98px) {
      @content;
    }
  }

  @else if $size ==md {
    @media (max-width: 991.98px) {
      @content;
    }
  }

  @else if $size ==lg {
    @media (max-width: 1199.98px) {
      @content;
    }
  }
}

//Currency
@mixin currency($sym) {
  &::after {
    content: '#{$sym}';
  }
}

// Grids
@mixin gridCards($type: fit, $min: 280px, $max: 1fr, $gap: 30px) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat(auto-#{$type}, minmax($min, $max));
}

// Адаптивное свойство (clamp)
@mixin adaptiveValue($property,
  $startSize,
  $minSize,
  $widthFrom: $containerWidth,
  $widthTo: $minWidth,
  $keepSize: 0) {
  @if ($startSize==0) {
    $startSize: 0.000001;
  }

  @if ($minSize==0) {
    $minSize: 0.000001;
  }

  // Для calc();
  $addSize: math.div($startSize - $minSize, 16);

  @if ($widthFrom ==$containerWidth and $maxWidthContainer ==0) {
    $widthFrom: $maxWidth;
  }

  // Брейк-поинты в EM
  $widthFromMedia: em($widthFrom);
  $widthToMedia: em($widthTo);

  // Формула плавающего значения
  // Источник: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
  $slope: math.div(($startSize - $minSize), ($widthFrom - $widthTo));
  $yIntersection: -$widthTo * $slope + $minSize;

  @if ($yIntersection==0) {
    $yIntersection: 0.000001;
  }

  $flyValue: #{rem($yIntersection)}' + '#{$slope * 100}vw;

  // Получение значения свойства
  $propertyValue: #{'clamp(' rem($minSize) ',' $flyValue ',' rem($startSize) ')'};

  // Если отрицательные значения
  @if ($minSize > $startSize) {
    $propertyValue: #{'clamp('
 rem($startSize) ','
    $flyValue ','
    rem($minSize) ')'
  }

  ;
}

// Если поддерживается clamp();
@supports (#{$property}: $propertyValue) {
  #{$property}: $propertyValue;
}

// Если не поддерживается clamp();
@supports not (#{$property}: $propertyValue) {
  #{$property}: calc(#{rem($minSize)} + #{$addSize} * (100vw - #{rem($widthTo)}) / #{math.div(
 $widthFrom,
    16) - math.div($widthTo, 16)
});
}

// Устанавливаем значения по умолчанию
@if $widthFrom !=$containerWidth and $widthFrom !=$maxWidth and $keepSize !=1 and $keepSize !=2 {
  @media (min-width: $widthFromMedia) {
    #{$property}: inherit;
  }
}

@if $widthTo !=$minWidth and $keepSize !=1 and $keepSize !=3 {
  @media (max-width: $widthToMedia) {
    #{$property}: inherit;
  }
}
}

@mixin rtl {
  [dir="rtl"] {
    @content;
  }
}