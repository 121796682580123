
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.popup-content {

  img {
    margin-bottom: rem(16);
  }

  // .popup-content__title

  &__title {
    font-weight: 700;
    font-size: rem(24);
    line-height: math.div(32, 24);
    text-align: center;
    margin-bottom: rem(16);
  }

  // .popup-content__desc

  &__desc {
    color: $light-gray-color;
    margin-bottom: rem(16);
  }

  // .popup-content__btn

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: rem(16);
  }
}
