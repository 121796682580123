
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.search-link {
  font-size: rem(24);
  padding: rem(8);
  color: $search-link-color !important;

  &:hover {
    color: lighten($color: $search-link-color, $amount: 15%) !important;
  }

  @include media-breakpoint-down(xs) {
    color: $light-gray-color !important;
    font-size: rem(18);
    padding: rem(0);
  }
}
