
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.auth-sign-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 rem(30);

  margin-bottom: rem(32);

  &__title {
    color: $base-color-font;
    font-size: rem(36);
    font-weight: 700;
    line-height: math.div(40, 36);
    margin-bottom: rem(10);
  }

  &__desc {
    color: $light-gray-color;
    font-size: rem(16);
    font-weight: 400;
    line-height: math.div(21, 16);
    max-width: rem(420);
  }
}
