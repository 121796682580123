// Заголовки, общие БЄМ блоки и проче...
a,
a:visited,
a:link {
  font-family: 'IBMPlexSansArabic', sans-serif;
  color: $base-color-font;

  &:hover {
    text-decoration: none;
  }

  &.link__primary {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

html {
  @extend %scroll-x;
}

// margin
.m {
  &__t--1 {
    margin-top: rem(10);
  }

  &__t--2 {
    margin-top: rem(13);
  }

  &__t--3 {
    margin-top: rem(16);
  }

  &__t--4 {
    margin-top: rem(20);
  }

  &__t--5 {
    margin-top: rem(25);
  }

  &__t--6 {
    margin-top: rem(40);
  }

  &__b--0 {
    margin-bottom: 0;
  }

  &__b--1 {
    margin-bottom: rem(10);
  }

  &__b--2 {
    margin-bottom: rem(13);
  }

  &__b--3 {
    margin-bottom: rem(16);
  }

  &__b--4 {
    margin-bottom: rem(20);
  }

  &__b--5 {
    margin-bottom: rem(25);
  }

  &__b--6 {
    margin-bottom: rem(30);
  }

  &__b--7 {
    margin-bottom: rem(60);
  }

  &__l--1 {
    margin-left: rem(10);
  }

  &__l--2 {
    margin-left: rem(13);
  }

  &__l--3 {
    margin-left: rem(16);
  }

  &__l--4 {
    margin-left: rem(20);
  }

  &__l--5 {
    margin-left: rem(25);
  }

  &__l--6 {
    margin-left: rem(30);
  }

  &__r--1 {
    margin-right: rem(10);
  }

  &__r--2 {
    margin-right: rem(13);
  }

  &__r--3 {
    margin-right: rem(16);
  }

  &__r--4 {
    margin-right: rem(20);
  }

  &__r--5 {
    margin-right: rem(25);
  }

  &__r--6 {
    margin-right: rem(30);
  }
}

[dir=rtl] {
  .m {

    &__l--1 {
      margin-left: rem(0);
      margin-right: rem(10);
    }

    &__l--2 {
      margin-left: rem(0);
      margin-right: rem(13);
    }

    &__l--3 {
      margin-left: rem(0);
      margin-right: rem(16);
    }

    &__l--4 {
      margin-left: rem(0);
      margin-right: rem(20);
    }

    &__l--5 {
      margin-left: rem(0);
      margin-right: rem(25);
    }

    &__l--6 {
      margin-left: rem(0);
      margin-right: rem(30);
    }

    &__r--1 {
      margin-right: rem(0);
      margin-left: rem(10);
    }

    &__r--2 {
      margin-right: rem(0);
      margin-left: rem(13);
    }

    &__r--3 {
      margin-right: rem(0);
      margin-left: rem(16);
    }

    &__r--4 {
      margin-right: rem(0);
      margin-left: rem(20);
    }

    &__r--5 {
      margin-right: rem(0);
      margin-left: rem(25);
    }

    &__r--6 {
      margin-right: rem(0);
      margin-left: rem(30);
    }
  }
}

// padding
.p {
  &__t--1 {
    margin-top: rem(10);
  }

  &__t--2 {
    margin-top: rem(13);
  }

  &__t--3 {
    margin-top: rem(16);
  }

  &__t--4 {
    margin-top: rem(20);
  }

  &__t--5 {
    margin-top: rem(25);
  }

  &__t--6 {
    margin-top: rem(40);
  }

  &__b--0 {
    margin-bottom: 0;
  }

  &__b--1 {
    margin-bottom: rem(10);
  }

  &__b--2 {
    margin-bottom: rem(13);
  }

  &__b--3 {
    margin-bottom: rem(16);
  }

  &__b--4 {
    margin-bottom: rem(20);
  }

  &__b--5 {
    margin-bottom: rem(25);
  }

  &__b--6 {
    margin-bottom: rem(30);
  }

  &__b--7 {
    margin-bottom: rem(60);
  }

  &__l--1 {
    margin-left: rem(10);
  }

  &__l--2 {
    margin-left: rem(13);
  }

  &__l--3 {
    margin-left: rem(16);
  }

  &__l--4 {
    margin-left: rem(20);
  }

  &__l--5 {
    margin-left: rem(25);
  }

  &__l--6 {
    margin-left: rem(30);
  }
}

.hidden {
  &__sm {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__md {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.text {
  &__center {
    text-align: center;
  }

  &__right {
    text-align: right;
  }
}

// width

.w {
  &__max {
    &--305 {
      max-width: rem(305);
    }

    &--554 {
      max-width: rem(554);
    }

    &--703 {
      max-width: rem(703);
    }

    @include media-breakpoint-down(xs) {
      &--158 {
        max-width: rem(158);
      }

      &--100 {
        max-width: 100%;
      }
    }
  }
}

// flex style
.d {
  &__flex {
    display: flex;
  }
}

.flex {
  &__align-item {
    &--center {
      align-items: center;
    }

    &--start {
      align-items: flex-start;
    }
  }

  &__justify-content {
    &--between {
      justify-content: space-between;
    }

    &--start {
      justify-content: flex-start;
    }
  }

  &__grow {
    &--1 {
      flex-grow: 1;
    }

    &--2 {
      flex-grow: 2;
    }
  }
}

// content center flex
%flex__content {
  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fs__small {
  font-size: rem(14);
}

// button
.button__group {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs) {
    column-gap: rem(20);
  }
}

// icon style
.icon {
  &__block {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem(50);
    height: rem(50);
    border-radius: 50%;

    background: rgba(113, 119, 255, 0.12);
    backdrop-filter: blur(17px);
  }

  &__close {
    display: block;
    position: relative;
    width: rem(20);
    height: rem(20);

    &:before,
    &:after {
      display: block;
      position: absolute;
      left: 50%;
      content: " ";
      height: rem(20);
      width: rem(2);
      background-color: $primary-color;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.hide {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.divider {
  width: 100%;
  height: rem(1);
  background: $divider-bg;
  display: block;

  &__vertical {
    width: rem(1);
    height: rem(26);
    // border-right: rem(1) solid $footer-color-border;
  }
}

.block-scrolle {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  // @extend %scroll-w;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: rem(25);
  height: rem(25);

  .path {
    // stroke: $spinner-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.fading {
  animation: disappearing 0.5s forwards;
}

@keyframes appearing {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappearing {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes hide-warning {
  0% {
    transform: translatex(0);
  }

  50% {
    transform: translatex(-5%);
  }

  100% {
    transform: translatex(100%);
  }
}

@keyframes hide-warning-reverse {
  0% {
    transform: translatex(100%);
  }

  50% {
    transform: translatex(-5%);
  }

  100% {
    transform: translatex(0);
  }
}

@keyframes hide-warning-rtl {
  0% {
    transform: translatex(0);
  }

  50% {
    transform: translatex(5%);
  }

  100% {
    transform: translatex(-100%);
  }
}

@keyframes hide-warning-reverse-rtl {
  0% {
    transform: translatex(-100%);
  }

  50% {
    transform: translatex(5%);
  }

  100% {
    transform: translatex(0);
  }
}