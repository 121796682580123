
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.btn__close {
  height: rem(30);
  width: rem(30);
  min-width: auto;
  padding: 0;
  opacity: .3;

  @extend %flex__content--center;

  &:hover {
    opacity: 1;
  }

}
