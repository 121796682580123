
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.auth-sign__logo {
	display: flex;
	justify-content: center;
	height: rem(30);
	margin-bottom: rem(32);
}
