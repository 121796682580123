
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

@mixin transition-ease {
  transition: all 0.3s ease-in-out;
}

.header-mobile {


  // .header-mobile__btn

  &__btn {
    font-size: rem(18);
    // padding: rem(8);
  }

  // .header-mobile__dropdown

  &__dropdown {
    background-color: $main-bg;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
    opacity: 1;
    visibility: hidden;
    transform: translateX(-100%);
    @include transition-ease;

    &.show {
      visibility: visible;
      transform: translateX(0);
    }

    // .header-mobile__header

    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: rem(10);
      padding: rem(25) rem(20);
      border-bottom: rem(1) solid $base-gray-color;
    }

    // .header-mobile__body

    &-body {}

    &-nav {
      li {
        border-bottom: rem(1) solid $base-gray-color;
      }

      a {
        color: $light-gray-color;
        font-weight: 700;
        font-size: rem(16);
        line-height: math.div(28, 22);
        display: block;
        padding: rem(10) rem(20);
        widows: 100%;
      }
    }
  }

}
