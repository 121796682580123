
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.lang {
  display: flex;
  align-items: center;
  column-gap: rem(15);

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &__pointer {
    color: $light-gray-color;
    cursor: pointer;

    &.active,
    &:hover {
      color: $base-color-font;
    }
  }

  &__icon {
    width: rem(21);
    background-image: url('~@/assets/img/world.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

[dir="rtl"] {
  .lang {
    margin-left: 0;
    margin-right: rem(30);
  }
}
