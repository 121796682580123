
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.card-form {
  &__item-btn {
    margin-bottom: rem(16);
  }

  &__item-link {
    color: $light-gray-color;
    text-align: center;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}
