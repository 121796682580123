.table {
	width: 100%;

	&>thead {
		vertical-align: bottom;

		&>*>* {

			opacity: 1;
		}
	}

	&>*>*>* {
		font-weight: 400;
		font-size: rem(16);
		line-height: math.div(24, 16);
		padding: rem(16);
		border-bottom: rem(1) solid $table-striped-bg;
		opacity: .5;
	}


	&-striped {
		&>tbody>tr:nth-of-type(even)>* {
			background-color: $table-striped-bg;
		}
	}
}