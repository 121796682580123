
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.user-data {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__name {
    color: $base-color-font;
    font-size: rem(16);
    font-weight: 600;
    line-height: math.div(20, 16);
  }

  &__email {
    color: $light-gray-color;
    font-size: rem(16);
    font-weight: 400;
    line-height: math.div(19, 16);
  }

  &__image {
    height: rem(45);
    width: rem(45);
    border-radius: 50%;
    overflow: hidden;
    margin-right: rem(16);

    @include media-breakpoint-down(xs) {
      height: rem(26);
      width: rem(26);
      margin-right: 0;
    }

    img {
      width: rem(45);
      height: rem(45);

      @include media-breakpoint-down(xs) {
        height: rem(24);
        width: rem(24);
      }
    }
  }

  &__icon {
    height: rem(45);
    width: rem(45);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-gray-color;

    @include media-breakpoint-down(xs) {
      height: rem(26);
      width: rem(26);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }
}

.user-dropdown {
  position: relative;
  margin-right: rem(30);
  margin-left: rem(30);

  @include media-breakpoint-down(xs) {
    margin: 0;
    position: absolute;
    right: rem(17);
  }

  &__btn {
    @include media-breakpoint-down(xs) {
      .fa-angle-down {
        display: none;
      }
    }
  }

  &__menu {
    background-color: $dropdown-bg;
    border-radius: rem(5);
    min-width: rem(262);

    position: absolute;
    top: rem(69);
    right: 0;
    z-index: 99;

    @include media-breakpoint-down(xs) {
      top: rem(50);
    }
  }

  &__item {
    padding: rem(15);
    cursor: pointer;


    &.divider {
      padding: 0;
    }

    a,
    span {
      display: block;
    }
  }

  &__link {
    color: $light-gray-color;
    font-size: rem(16);
    font-weight: 400;
    line-height: math.div(19, 16);

    &:hover {
      color: $base-color-font;
    }
  }
}

[dir="rtl"] {
  .user-data {
    &__image {
      margin-right: 0;
      margin-left: rem(16);
    }
  }
}
