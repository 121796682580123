.panel {

  // .panel__header

  &__header {
    margin-bottom: rem(19);
  }

  // .panel__title

  &__title {
    font-weight: 700;
    font-size: rem(36);
    line-height: math.div(54, 36);

    @include media-breakpoint-down(xs) {
      font-size: rem(18);
      line-height: math.div(28, 18);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: rem(32);
    row-gap: rem(48);
    justify-items: center;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: rem(12);
      row-gap: rem(20);
    }
  }
}