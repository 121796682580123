
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.form__controle-focus {
  label {
    pointer-events: auto;
    top: rem(5);
    opacity: 1;
    font-size: 12px;
  }
}

.auth-sign {
  &__recover-password {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(32);

    a {
      color: $primary-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__button {
    margin-bottom: rem(16);

    .btn__border {
      width: 100%;
    }
  }
}
