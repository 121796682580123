.page {

  // .page__header

  &__header {
    font-weight: 700;
    font-size: rem(36);
    line-height: math.div(40, 36);
    margin-bottom: rem(32);

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: rem(20);

    @include media-breakpoint-down(xs) {
      margin-bottom: rem(20);
    }
  }

  &__title {
    @include media-breakpoint-down(xs) {
      font-size: rem(24);
    }

  }

  // .page__content

  &__content {
    margin-bottom: rem(80);

    @include media-breakpoint-down(xs) {
      margin-bottom: rem(40);
    }
  }
}

.sign-in-link {
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}