
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.bunner-ads {
	display: flex;
	// display: none;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-direction: column;

	&__item {
		background-color: $base-gray-color;
		text-align: center;
		width: auto;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__lable {
		width: auto;
		font-size: 12px;
	}

	&.small {
		.bunner-ads__item {
			min-height: rem(90);
		}
	}

	&.big {
		.bunner-ads__item {
			height: rem(250);
		}
	}
}
