
          @use "sass:math";
          @import "@/assets/scss/style.scss";
        

.wrapper {
  background-color: $main-bg;
}

html[dir="rtl"] .general-glider-prev {
  transform: rotateY(180deg) translateX(-80px);
}

html[dir="rtl"] .VueCarousel-inner {
  direction: ltr;
}
