:root {
	--maz-color-bg: #1E252E;
	--maz-color-text: #fff;
	--maz-color-muted: #fff;
	--maz-color-black: #fff;
}

.maz-border {
	border-width: 1px;
}

.m-select-list-item.--is-selected.--black {
	background-color: #7CE7F2 !important;
	color: #000 !important;
}

.m-select-list-item:hover {
	background-color: #7CE7F2 !important;
	color: #000 !important;
}